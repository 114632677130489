'use client';
import type { ReactNode } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import Symbol from '../../../public/images/symbol-watermark.svg';
import HeartSymbol from '../../../public/images/symbol-heart.svg';
import SearchInput from 'src/components/SearchInput';
import { locationUrl } from 'src/services/route-utils';

type Props = {
    align: 'left' | 'right';
    dark: boolean;
    imageSrc: string;
    variant: 'cover' | 'symbol' | 'cutout';
    action: 'none' | 'button' | 'location-search';
    actionTitle?: string;
    actionUrl?: string;
    title: string;
    description?: string;
    active?: boolean;
    priority?: boolean;
    shouldLoadImage?: boolean;
    renderHeaderAs?: 'h1' | 'h2';
};

export default function CarouselItem({
    align,
    dark,
    imageSrc,
    variant,
    title,
    description,
    action,
    actionTitle,
    actionUrl,
    active = true,
    priority,
    shouldLoadImage,
    renderHeaderAs = 'h1',
}: Props) {
    let btnClassName = 'btn-primary';
    let sizes = '100vw';
    switch (variant) {
        case 'cover':
            sizes = '100%';
            btnClassName = dark ? 'btn-secondary' : 'btn-primary';
            break;
        case 'cutout':
            sizes =
                '(max-width: 640px) 320px, (max-width: 768px) 384px, (max-width: 840px) 420px, (max-width: 1024px) 512px, (max-width: 1280px) 640px, 768px';
            btnClassName = dark ? 'btn-secondary' : 'btn-primary';
            break;
        case 'symbol':
            sizes = '(max-width: 640px) 320px, (max-width: 768px) 384px, 512px';
            break;
    }

    const image = (
        <Image
            src={imageSrc}
            alt={title}
            className={`absolute -z-10 ${variant === 'symbol' ? 'object-contain object-bottom' : 'object-cover'}`}
            fill
            quality={75}
            sizes={sizes}
            priority={priority}
            loading={shouldLoadImage ? 'eager' : undefined}
        />
    );

    const gradientSteps = dark
        ? '#FFFFFF00 0%, #3E3E3E46 29%, #3E3E3E46 57%, #0000005D 80%, #000000FF 100%'
        : '#FFFFFF00 0%, #FFFFFF9E 29%, #FFFFFFC0 57%, #FFFFFFE4 80%, #FFFFFFFF 100%';
    const shadowDiv = (
        <>
            {image}
            <div
                className={`absolute top-0 -z-10 h-full ${align === 'left' ? 'left-0' : 'right-0'} w-4/5 lg:w-2/3`}
                style={{
                    background: `transparent linear-gradient(${
                        align === 'left' ? '270' : '90'
                    }deg, ${gradientSteps}) 0% 0% no-repeat padding-box`,
                    opacity: dark ? 0.7 : undefined,
                }}
            />
        </>
    );

    const symbolDiv = (
        <div
            className={`absolute left-0 top-0 -z-10 h-full w-full p-8 text-white md:p-12 ${
                dark ? 'bg-light-green' : 'bg-light-grey'
            }`}>
            <div className="relative flex h-full">
                <Symbol
                    className={`absolute h-full ${
                        align === 'left' ? 'right-0 md:left-1/2' : 'left-0 md:left-auto md:right-1/2'
                    }`}
                />
            </div>
            <div className={`absolute ${align === 'left' ? 'right-0' : 'left-0'} top-0 z-10 h-full w-full md:w-1/2`}>
                <div
                    className={`absolute ${
                        align === 'left' ? 'right-0 translate-x-1/2' : 'left-0 -translate-x-1/2'
                    } top-0 h-full w-full md:transform-none lg:w-3/4`}>
                    {image}
                </div>
            </div>
        </div>
    );

    const cutoutDiv = (
        <>
            {align === 'right' && (
                <div
                    className={`absolute left-0 top-0 -z-10 h-full w-1/2 ${dark ? 'text-primary' : 'text-light-grey'}`}>
                    {image}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 198.83 400"
                        className="absolute right-0 -mr-px h-full">
                        <path
                            d="m194.04,335.11c0-150.73-80.03-280.03-194.04-335.11h198.83v400h-9.83c3.31-21.06,5.04-42.74,5.04-64.89Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
            )}
            <div
                className={`absolute -z-10 ${
                    dark ? 'bg-primary text-white' : 'bg-light-grey'
                } top-0 h-full w-1/2 overflow-hidden`}>
                <div className="h-full w-full" style={align === 'right' ? { transform: 'scale(-1,1)' } : undefined}>
                    <HeartSymbol className={`absolute bottom-0 h-1/2 opacity-5 lg:h-3/4`} />
                </div>
            </div>
            {align === 'left' && (
                <div
                    className={`absolute left-1/2 top-0 -z-10 h-full w-1/2 ${
                        dark ? 'text-primary' : 'text-light-grey'
                    }`}>
                    {image}
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 198.83 400" className="absolute -ml-px h-full">
                        <path
                            d="m4.79,335.11C4.79,184.38,84.82,55.08,198.83,0H0v400h9.83c-3.31-21.06-5.04-42.74-5.04-64.89Z"
                            fill="currentColor"
                        />
                    </svg>
                </div>
            )}
        </>
    );

    let extra: ReactNode = null;
    switch (action) {
        case 'location-search':
            extra = (
                <SearchInput
                    id="locations-search"
                    placeholder="Caută după localitate"
                    redirectUrl={locationUrl(null)}
                    filterParam="q"
                />
            );
            break;
        case 'button':
            extra = actionUrl && actionTitle && (
                <Link className={`btn ${btnClassName} inline-block text-xs md:text-base lg:px-16`} href={actionUrl}>
                    {actionTitle}
                </Link>
            );
    }

    const Heading = renderHeaderAs;

    return (
        <div className={`relative flex h-full items-end ${align === 'right' ? 'justify-end' : ''}`}>
            {variant === 'symbol' && symbolDiv}
            {variant === 'cutout' && cutoutDiv}
            {variant === 'cover' && shadowDiv}

            <div
                className={`absolute flex w-2/3 flex-col px-4 pb-4 transition-all duration-1000 md:w-1/2 md:px-8 md:pb-16 lg:pb-24 xl:w-3/5 xl:px-16 ${
                    align === 'right' ? 'items-end text-right' : ''
                } ${variant !== 'symbol' && dark ? 'text-white' : ''} ${
                    active ? 'bottom-4 opacity-100 md:bottom-20' : '-bottom-1/2 opacity-25 md:-bottom-1/4'
                }`}>
                <Heading
                    className={`${
                        title.length <= 45 ? 'text-4xl md:text-5xl' : 'text-3xl md:text-4xl'
                    } lg:text-6xl 2xl:text-7xl`}>
                    {title}
                </Heading>
                <div className="lg:w-2/3">
                    {description && <p className="mt-8 text-sm md:text-xl">{description}</p>}
                    <div className="mt-4">{extra}</div>
                </div>
            </div>
        </div>
    );
}
