'use client';

import { useRouter, useSearchParams } from 'next/navigation';
import { FormEventHandler, forwardRef } from 'react';
import { HiChevronRight, HiSearch } from 'react-icons/hi';

type Props = {
    id: string;
    disabled?: boolean;
    placeholder?: string;
    onSubmit?: (searchString: string) => void;
    redirectUrl?: string;
    filterParam?: string;
    minLength?: number;
    buttonAriaLabel?: string;
};

const SearchInput = forwardRef<HTMLFormElement, Props>(
    (
        {
            id,
            disabled = false,
            placeholder = 'Căutare',
            onSubmit,
            minLength = undefined,
            redirectUrl = null,
            filterParam = 'filter',
            buttonAriaLabel = 'Caută',
        },
        ref,
    ) => {
        const router = useRouter();
        const searchParams = useSearchParams();

        const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
            const data = Object.fromEntries(new FormData(event.target as any)) as any;
            if (typeof redirectUrl === 'string' && redirectUrl) {
                router.push(`${redirectUrl}?${new URLSearchParams({ [filterParam]: data.search?.trim() }).toString()}`);
            }
            if (typeof onSubmit === 'function') {
                onSubmit(data.search);
            }

            event.preventDefault();
        };

        return (
            <form id={id} ref={ref} className="relative w-full" onSubmit={handleSubmit}>
                <label
                    htmlFor={`${id}-search-input`}
                    className="relative block w-full"
                    aria-label="Introduceți termenul de căutare">
                    <HiSearch
                        className="pointer-events-none absolute left-3 mt-3 text-lg text-slate-400"
                        title={placeholder}
                        aria-hidden
                    />
                    <input
                        defaultValue={searchParams?.get(filterParam) || ''}
                        id={`${id}-search-input`}
                        name="search"
                        disabled={disabled}
                        type="search"
                        className="focus:border-outline-green focus:ring-outline-green w-full rounded-full border border-slate-400 px-8 py-2 pr-16 focus:outline-none focus:ring-1"
                        placeholder={placeholder}
                        autoComplete="off"
                        minLength={minLength}
                    />
                </label>
                <button
                    type="submit"
                    aria-label={buttonAriaLabel}
                    disabled={disabled}
                    className="bg-primary hover:bg-primary-hover absolute bottom-0 right-0 top-0 rounded-r-full px-4 text-white">
                    <HiChevronRight className="text-2xl" />
                </button>
            </form>
        );
    },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
